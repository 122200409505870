//Add InfoIcon Tooltip to every class .infoicon
$(function () {
    $(".infoicon").tooltip({
        sanitize: false
    });
});

//Related to InfoIcon: If a "form__wrapper-text" contains a paragraph tag, remove it's 
//padding-bottom from the tag and add it to the wrapper, to prevent the info icon from shifting.
//Background for this solution: For text-elements you are able to select tags. p-tags have a padding-bottom styling, all other selectable tags not.
//At the time of implementation Firefox doesn't support the css ':has()' functionality, this is a workaround.
$(function () {
    var wrappers = $(".form__wrapper-text");
    wrappers.each(function () {
        var tag = $(this).find('p');
        if (tag.length) {
            var padding = tag.css("padding-bottom");
            tag.css({ paddingBottom: 0 });
            $(this).css({ paddingBottom: padding })
        }
    })
})

function initCascadingDropdown(configuration) {
    var currentElement = jQuery('#' + configuration.currentElementId);
    currentElement.data('level', configuration.level || 0);

    // Loading existing config.
    // [MB]
    if (!window.cascadingFields[configuration.rootElementId]) {
        window.cascadingFields[configuration.rootElementId] = {
            backingField: jQuery('#' + configuration.backingFieldId),
            elements: [currentElement]
        };
    }
    var currentConfig = window.cascadingFields[configuration.rootElementId];

    currentElement.bind('change', function () {
        var caller = jQuery(this);
        var callingId = caller.attr('id');
        var callingLevel = caller.data('level');

        // Preventing to fast switches.
        // [MB]
        caller.attr('disabled', 'disabled');

        // Hide all elements with higher levels.
        // [MB]
        var idList = '';
        currentConfig.elements.forEach(function (loopedElement) {
            if (loopedElement.data('level') <= callingLevel) {
                idList += loopedElement.val() + '|';
            }
            else {
                loopedElement.hide();
                loopedElement.html('');
            }
        });

        // Update hierachical IDs.
        // [MB]
        currentConfig.backingField.val(idList.substr(0, idList.length - 1));


        // Load the data for the next dropdown.
        // [MB]
        jQuery.ajax({
            url: '/api/concern/selectitems?itemId=' + caller.val(),
            method: 'GET'
        }).done(function (loadedItems) {
            caller.removeAttr('disabled');

            if (loadedItems && loadedItems.length) {
                var dependentDropdown = jQuery('select[depends-on=' + callingId + ']');

                // Create the dependent dropdown if not exists.
                // [MB]
                if (dependentDropdown.length === 0) {
                    var newDropdownId = callingId + '_sub';
                    var dependentDropdown = jQuery('<select id="' + newDropdownId + '" depends-on="' + callingId + '" data-level="' + (callingLevel + 1) + '" class="form-control" />');

                    currentConfig.elements.push(dependentDropdown);
                    dependentDropdown.insertAfter(caller);
                    dependentDropdown.bind('change',
                        function () {
                            var validator = dependentDropdown.closest("form").validate();
                            validator.element("#" + configuration.rootElementId);
                        });

                    initCascadingDropdown({
                        rootElementId: configuration.rootElementId,
                        currentElementId: newDropdownId,
                        backingFieldId: configuration.backingField,
                        pleaseSelectPlaceholder: configuration.pleaseSelectPlaceholder,
                        level: caller.data('level') + 1
                    });
                }

                dependentDropdown.append('<option value="" selected disabled>' + configuration.pleaseSelectPlaceholder + '</option>');
                for (var loadedItemIdx = 0; loadedItemIdx < loadedItems.length; loadedItemIdx++) {
                    dependentDropdown.append('<option value="' + loadedItems[loadedItemIdx].Value + '">' + loadedItems[loadedItemIdx].Text + '</option>')
                }
                dependentDropdown.show();
            }
        });
    });
};

(function ($) {
    var adapters = $.validator.unobtrusive.adapters;

    $.validator.addMethod("validateconcern", function (value, element) {
        if (this.optional(element)) {
            return true;
        }
        var valid = true;
        $(element).siblings("select:visible").each(function () {
            if ($(this).val() == null || $(this).val() === "") {
                valid = false;
            }
        });
        return valid;
    });

    adapters.add("validateconcern", function (options) {
        options.rules["validateconcern"] = {
        };

        options.messages["validateconcern"] = options.message;
    });
}) (jQuery);
$.validator.setDefaults({ ignore: ":hidden:not(.fxt-captcha)" });

/**
 * Google Recaptcha
 */
var reCaptchaArray = reCaptchaArray || [];
$.validator.unobtrusive.adapters.add("recaptcha", function (options) {
    options.rules["recaptcha"] = true;
    if (options.message) {
        options.messages["recaptcha"] = options.message;
    }
});

$.validator.addMethod("recaptcha", function (value, element, exclude) {
    return true;
});
var recaptchasRendered = false;
var loadReCaptchas = function () {
    for (var i = 0; i < reCaptchaArray.length; i++) {
        var reCaptcha = reCaptchaArray[i];
        if (reCaptcha.IsRendered === undefined) {
            reCaptcha.IsRendered = true;
            reCaptcha();
        }
    }
};

/**
 * File upload Content Type
 */
$.validator.unobtrusive.adapters.addSingleVal("contenttype", "allowedcontenttypes");

$.validator.addMethod("contenttype", function (value, element, allowedcontenttypes) {
    if (!this.optional(element)) {
        for (var i = 0; i < element.files.length; i++) {
            if (allowedcontenttypes.indexOf(element.files[i].type) < 0) {
                return false;
            }
        }
    }
    return true;
});

/**
 * File upload File Size
 */
$.validator.unobtrusive.adapters.addSingleVal("filesize", "maxfilesize");
$.validator.addMethod("filesize", function (value, element, maxfilesize) {
    if (!this.optional(element)) {
        for (var i = 0; i < element.files.length; i++) {
            if (element.files[i].size > maxfilesize) {
                return false;
            }
        }
    }
    return true;
});

// Date Time Span Validator
["timespan", "tsminagedate", "tsfuturedate", "tspastdate"].forEach(validationType =>{
  $.validator.unobtrusive.adapters.add(validationType, ['min', 'max', 'unit'], function(options) {
    options.rules[validationType] = [options.params.min, options.params.max, options.params.unit];
    options.messages[validationType] = options.message;
  });
  
  $.validator.addMethod(validationType, function (value, element, params) {
    if (!this.optional(element)) {
      var unit = params[2];
      var minvalue = params[0];
      var maxvalue = params[1];
  
      var valueToValidate = 0;
  
      switch (unit) {
        case 'days':
          valueToValidate = getDays(value);
          break;
        case 'months':
          valueToValidate = getMonths(value);
          break;
        case 'years':
          valueToValidate = getYears(value);
          break;
      }
  
      var isValid = true;
  
      if (typeof minvalue !== 'undefined' && valueToValidate < minvalue)
        isValid = false;
  
      if (typeof maxvalue !== 'undefined' && valueToValidate > maxvalue)
        isValid = false;
  
      return isValid;
    }
    return true;
  });
})

/*
$.validator.unobtrusive.adapters.add('timespan', ['min', 'max', 'unit'], function(options) {
  options.rules['timespan'] = [options.params.min, options.params.max, options.params.unit];
  options.messages['timespan'] = options.message;
});

$.validator.addMethod("timespan", timespanValidatorFunction);*/

function getDays(date) {
  var today = new Date();
  return Math.floor((today - new Date(date)) / (1000 * 60 * 60 * 24));
}

function getYears(date) {
  var today = new Date();
  var diffYears = today.getFullYear() - new Date(date).getFullYear();
  var temp = today;

  temp.setFullYear(temp.getFullYear() - diffYears);

  if (new Date(date) > temp)
    diffYears--;

  return diffYears;
}

function getMonths(date) {
  var today = new Date();
  var d = new Date(date);

  return (today.getFullYear() - d.getFullYear()) * 12 + today.getMonth() - d.getMonth();
}
var headerNavigation = function () {

    var data = {
        showMobileMenu: false,
        activeSubMenu: {
            private: true,
            business: false
        }
    };

    function toggleMobileMenu(callingMenu) {
        var burgerMenu = $(callingMenu);
        var topNavMenu = burgerMenu.closest('.top-nav');


        if (data.showMobileMenu) {
            data.showMobileMenu = false;
            $("body").removeClass("no-scroll");

            $(".top-nav__burger").css("display", "");

            burgerMenu.show();
            topNavMenu.removeClass('top-nav--mobileactive');

            $('.top-menu__list').removeClass('top-menu__list--show');
        } else {
            data.showMobileMenu = true;
            $("body").addClass("no-scroll");

            burgerMenu.hide();
            topNavMenu.addClass('top-nav--mobileactive');

            $('.top-menu__list').addClass('top-menu__list--show');
        }
    }

    //openSection: function (section, event) {
    //    $(".top-menu__nav-item").removeClass("top-menu__nav-item--active");
    //    $(event.target)
    //        .closest(".top-menu__nav-item")
    //        .addClass("top-menu__nav-item--active");
    //    $(".top-menu__list-container").hide();
    //    $('.top-menu__list-container[data-section="' + section + '"]').show();
    //},

    //openSubMenu: function (subMenu) {
    //    $('.top-menu__list-container[data-submenu="' + subMenu + '"]').show();
    //},

    //closeSubMenu: function () {
    //    $(".top-menu__list-container--submenu").hide();
    //},

    //openSubMenuItem: function (event) {
    //    $(event.target)
    //        .closest(".top-menu__list-item")
    //        .toggleClass("top-menu__list-item--active");
    //    $(event.target)
    //        .closest(".top-menu__list-item")
    //        .next(".top-menu__list-item-sub-container")
    //        .toggle();
    //}

    return {
        toggleMobileMenu: toggleMobileMenu
    }
}();
//export default () => {
//  if ($(".main-nav--gk").length) {
//    return new Vue({
//      el: ".main-nav",

//      data: {
//        accountMenuState: false
//      },

//      methods: {
//        toggleAccountMenu: function() {
//          this.accountMenuState = !this.accountMenuState;
//        }
//      }
//    });
//  }
//};

$(document).ready(function () {
    $('.form__custom-multiline-field-textarea').each(function () {
        var maxLength = $(this).attr('maxlength');
        var currentLength = $(this).val().length;
        $(this).after('<div class="form__custom-multiline-field-char-count">' + currentLength + '/' + maxLength + '</div>');
        $(this).on('input', function () {
            var currentLength = $(this).val().length;
            $(this).next('.form__custom-multiline-field-char-count').text(currentLength + "/" + maxLength);
        });
    });
});

function colorizeLabels() {
    $('.redlabel').removeClass('redlabel');

    $(".field-validation-error").each(function () {
        var IdOfElementWithErrorMessage = $(this).data("valmsg-for");

        IdOfElementWithErrorMessage = IdOfElementWithErrorMessage.replaceAll(/\[/g, "_");
        IdOfElementWithErrorMessage = IdOfElementWithErrorMessage.replaceAll(/\]/g, "_");
        IdOfElementWithErrorMessage = IdOfElementWithErrorMessage.replaceAll(/\./g, "_");
        IdOfElementWithErrorMessage = IdOfElementWithErrorMessage.replaceAll(/\"/g, "");

        $('label[for="' + IdOfElementWithErrorMessage + '"]').addClass("redlabel");
    });
}

$(function () {
    $('input, select').on('elementValidation', function () {
        colorizeLabels();
    });
    $('form').on('formValidation', function () {
        colorizeLabels();
    });
});
//import '../../components/top-nav/_top-nav.scss';

//export default () => {
//  if ($(".top-nav").length) {
//    return new Vue({
//      el: ".top-nav",

//      data: {
//        showMobileSearch: false,
//        showMobileMenu: false,
//        activeSubMenu: {
//          private: true,
//          business: false
//        }
//      },

//      methods: {
//        // toggle mobile search
//        toggleMobileSearch: function() {
//          this.showMobileSearch = !this.showMobileSearch;
//          serverBus.$emit("navigationBarChanged", this.showMobileSearch);          
//        },

//        // toggle mobile menu
//        toggleMobileMenu: function() {
//          if (this.showMobileMenu) {
//            this.showMobileMenu = false;
//            $("body").removeClass("no-scroll");
//          } else {
//            this.showMobileMenu = true;
//            $("body").addClass("no-scroll");
//          }
//        },

//        openSection: function(section, event) {
//          $(".top-menu__nav-item").removeClass("top-menu__nav-item--active");
//          $(event.target)
//            .closest(".top-menu__nav-item")
//            .addClass("top-menu__nav-item--active");
//          $(".top-menu__list-container").hide();
//          $('.top-menu__list-container[data-section="' + section + '"]').show();
//        },

//        openSubMenu: function(subMenu) {
//          $('.top-menu__list-container[data-submenu="' + subMenu + '"]').show();
//        },

//        closeSubMenu: function() {
//          $(".top-menu__list-container--submenu").hide();
//        },

//        openSubMenuItem: function(event) {
//          $(event.target)
//            .closest(".top-menu__list-item")
//            .toggleClass("top-menu__list-item--active");
//          $(event.target)
//            .closest(".top-menu__list-item")
//            .next(".top-menu__list-item-sub-container")
//            .toggle();
//        }
//      }
//    });
//  }
//};
